.PasteBar {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #ddd;
  padding: 10px;
}

.ClipboardInner {
  flex: 1;
  display: flex;

  flex-direction: row;

  padding: 5px;
  padding-left: 10px;
  border-radius: 10px;
  align-items: stretch;

  background: rgba(black, 0.25);
  &.isValidHttpUrl {
    background: rgba(blue, 0.125);
  }
}

.ClipboardPasteButton {
  position: absolute;
  top: 10px;
  right: 10px;
  bottom: 10px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 10px;
  background-color: rgba(white, 0.5);
  border-radius: 5px;
  padding: 0 20px;
  color: rgba(black, 0.5);
  font-weight: bold;
  cursor: pointer;
}

.ClipboardPasteButtonText {
  color: rgba(black, 0.5);
}

.ClipboardText {
  white-space: nowrap;
  width: calc(100% - 130px);
  color: rgba(black, 0.5);

  margin: 0 5px;

  -webkit-mask-image: linear-gradient(
    to right,
    black 0%,
    black 50%,
    transparent 100%
  );

  a {
    display: flex;
    gap: 10px;
    align-items: center;
    text-decoration: none;
    color: blue;
  }
}

.ClipboardTextIcon {
  display: flex;
  gap: 10px;
  font-size: 15px;
  align-items: center;
  color: black;
}
