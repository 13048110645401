.Loader {
  border: 10px solid rgba(white, 0.25);
  border-top: 10px solid rgba(black, 0.25);
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
