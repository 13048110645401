.MainMenuButton_Position {
  backdrop-filter: blur(5px);
}

.MainMenuButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background: #ccc;
  border: 3px solid white;
  background: yellow;

  pointer-events: all;

  transition: transform var(--ANIMATIONS_DURATION);

  &:active {
    transform: scale(0.95);
  }

  cursor: pointer;

  .LogoWord {
    font-size: 30px;
    font-weight: bold;

    margin: 0 5px;
    --OUTLINE-WIDTH: 3px;

    --OUTLINE-OPACITY: 1;
    --OUTLINE-BASECOLOR: 255, 255, 255;
    --OUTLINE-COLOR: rgba(var(--OUTLINE-BASECOLOR), var(--OUTLINE-OPACITY));

    filter: drop-shadow(var(--OUTLINE-WIDTH) 0 0 var(--OUTLINE-COLOR))
      drop-shadow(0 var(--OUTLINE-WIDTH) 0 var(--OUTLINE-COLOR))
      drop-shadow(calc(var(--OUTLINE-WIDTH) * -1) 0 0 var(--OUTLINE-COLOR))
      drop-shadow(0 calc(var(--OUTLINE-WIDTH) * -1) 0 var(--OUTLINE-COLOR));
  }

  .LogoLetter {
    padding: 5px;
  }
}
