.App {
  flex: 1;
  display: flex;
  flex-direction: row;
  -webkit-tap-highlight-color: transparent;
  max-width: 600px;
  position: absolute;
  inset: 0;
}

// we almost always would want to control visible scrollbars tightly, so no scrollbars as a default!
*::-webkit-scrollbar {
  display: none;
}
