.Button {
  display: flex;
  align-items: center;
  // outline: 1px solid red;

  &:not(.isDisabled) {
    cursor: pointer;
  }

  transition: transform 0.2s;
  &:not(.isDisabled):active {
    transform: scale(0.95);
  }

  .ButtonIcon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Caption {
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: max-content;
    font-weight: bold;
  }

  .SmallCaption {
    font-size: 13px;
    font-weight: normal;
    opacity: 0.75;
    display: flex;
    gap: 5px;
  }

  &.hasIconAndCaption .Caption {
    padding-left: 0;
  }

  &.hasIconJsx .Caption {
    padding-left: 0 !important;
  }
}
