.ListTopMarker_Position {
  height: 50px;
  min-height: 50px;
}

.ListTopMarker {
  flex: 1;
  display: flex;
  font-size: 15px;
  align-items: center;
  justify-content: center;
  padding: 5px 20px;
  color: rgba(black, 0.75);
}
