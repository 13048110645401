.BottomSheetContent {
  position: absolute;
  pointer-events: all;
  will-change: transform;

  background-color: aqua;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-end;

  background: transparent;

  color: black;

  bottom: 0;
  width: calc(100vw);
  max-width: 400px;
  max-height: calc(100vh);

  &.isFullscreen {
    max-width: 100vw;
    width: 100vw;
    top: 0;
  }

  &.isSwiping {
    > * {
      pointer-events: none;
    }

    .Button {
      &:active {
        transform: scale(1);
      }
    }
  }

  //border: 2px solid chocolate;
  // outline: 2px solid yellow;
}

.BottomSheetChildren {
  pointer-events: auto;

  position: relative;
  display: flex;
  flex-direction: column;

  overflow: visible;

  -webkit-mask-image: linear-gradient(
    to bottom,
    transparent 0%,
    black 5px,
    black calc(100% - 5px),
    transparent 100%
  );

  overflow-y: scroll;
}

.BottomSheetHeaderHandler {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    content: "";
    background-color: rgba(0, 0, 0, 0.125);
    height: 2px;
    width: 100px;
    margin: 1px;
  }
}
