.ShoppingItemIconIsBoughtCheck {
  border-radius: 100px;
  background: black;
  display: flex;
  min-width: 16px;
  min-height: 16px;
  height: 16px;
  width: 16px;
  align-items: center;
  justify-content: center;

  // https://medium.com/@jeandesravines/use-border-radius-and-outline-simultaneously-on-safari-14ce92889e1f
  &::before {
    content: "";
    position: absolute;
    top: -1.5px;
    right: -1.5px;
    bottom: -1.5px;
    left: -1.5px;
    border: 1.5px solid #ccc;
    border-radius: 100px;
  }
}

.ShoppingItemIconIsBoughtCheck svg {
  opacity: 0;
  font-size: 10px;
}

.isInactive {
  .ShoppingItemIconIsBoughtCheck svg {
    opacity: 1;
  }
}
