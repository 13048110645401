.BasicItemImage {
  display: flex;
  align-items: center;

  max-width: 600px;
  max-height: 200px;
  overflow: hidden;
  border-radius: 5px;

  img {
    height: 100%;
    width: auto;
  }
}
