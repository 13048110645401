.BasicItemText {
  color: black;
  line-height: 1.25em;
  flex: 1;

  // outline: 1px dotted red;
  // outline: 0;
  border: 1px solid rgba(white, 0.25);
  border-radius: 5px;
  padding: 5px;

  &.isEmpty {
    color: rgba(black, 0.5);
  }

  &.isEditable {
    border: 1px solid #ddd;

    &:focus {
      outline: 0;
      border: 1px solid #ccc;
      background: rgba(255, 255, 255, 0.25);
      color: black;
    }
  }

  &[contenteditable="true"]:empty:before {
    content: attr(data-placeholder);
    pointer-events: none;
    color: rgba(black, 0.5);
    display: block; /* For Firefox */
  }
}
