.BasicItem {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;

  // outline: 0;

  color: black;

  gap: 5px;
  border-radius: 10px;
  margin: 1px;
}

.BasicItem.isInactive {
  opacity: 0.5;
  .BasicItemText {
    text-decoration: line-through;
  }
}
