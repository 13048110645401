.AddItemMenuTile {
  overflow: hidden;

  .TileInner {
    border-radius: 10px;
    height: 100px;
    flex: 1;
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    color: black;
    min-width: 80px;

    gap: 10px;

    span {
      font-size: 15px;
      color: rgba(black, 0.5);
    }

    transition: transform 0.2s;
    &:active {
      transform: scale(0.95);
    }
  }
}
