.LinkItem {
  display: flex;
  flex-direction: column;
  gap: 5px;

  overflow: hidden;
  border-radius: 10px;

  padding: 10px 0;

  .LinkDescription {
    color: rgba(black, 0.5);
  }

  .LinkPublisher {
    font-weight: bold;
    font-size: 15px;
  }
}

.LinkItem {
  a {
    color: blue !important;
    text-decoration: none;
  }

  &.hasFailed {
    a {
      color: red !important;
    }
  }
}

.LinkItemUrl {
  width: calc(100vw - 165px);
  overflow: hidden;
  white-space: nowrap;

  -webkit-mask-image: linear-gradient(
    to left,
    transparent 0%,
    black 50%,
    black 100%
  );
  mask-image: linear-gradient(to left, transparent 0%, black 50%, black 100%);
}
