.BackgroundSelectionChoice {
  border: 3px solid #eee;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  cursor: pointer;

  &.isSet {
    border-color: yellow;
  }
}
