.ScrollDownButton {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex: 1 1;

  background: #fff;
  border-radius: 100px;
}
