.ItemToolBar {
  display: flex;
  align-items: center;
  border-radius: 10px;
  align-self: stretch;
  padding: 0 5px;

  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  &.isOpened {
  }
}
