.BottomSheet {
  //border: 2px solid red;
  -webkit-tap-highlight-color: transparent;

  align-items: center;
  overflow: hidden;

  .Button {
    flex: 1;
    margin: 10px;

    color: white;
    background: black;

    &.secondary {
      color: rgba(black, 0.75);
      background: transparent;

      .Caption {
        font-weight: normal;
      }

      border: 1px solid rgba(black, 0.25);
    }

    justify-content: center;
  }
}
