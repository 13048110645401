@import url(./reset.scss);

html {
  user-select: none;
  touch-action: manipulation;
  font-family: sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body,
html,
#root,
.App {
  flex: 1;
  display: flex;
  margin: 0;
  padding: 0;
  // overflow: hidden;
  font-size: 17px;

  /* https://developer.chrome.com/blog/overscroll-behavior/#disabling-overscroll-glow-and-rubberbanding-effects */
  overscroll-behavior-x: none;
  overscroll-behavior-y: none;
}

html {
  position: fixed;
  inset: 0;
}

#root {
  justify-content: center;
}
