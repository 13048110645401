.ImageItem {
  display: flex;

  margin: 5px 0;
  flex-direction: column;
  gap: 5px;

  border-radius: 10px;

  // overflow: hidden;

  margin: 5px 0;

  &.hasError {
    padding: 5px;
  }

  img {
    width: 100%;
    height: min-content;
    border-radius: 10px;
  }
}
