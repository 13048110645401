.ItemsList {
  flex: 1;
  display: flex;
  flex-direction: column-reverse;

  min-width: 360px;

  transition: transform calc(var(--ANIMATIONS_DURATION) / 2);

  padding: 10px;
  padding-bottom: calc(var(--VERTICAL_MARGIN) + 120px);

  overflow-x: hidden;
  overflow-y: auto;

  overscroll-behavior-x: none;
  overscroll-behavior-y: none;

  will-change: scroll-position;

  gap: 10px;

  &::-webkit-scrollbar {
    display: none;
  }

  &.isItemOpened {
    .Item {
      opacity: 0.5 !important;

      &.isOpened {
        opacity: 1 !important;
      }
    }
  }
}
