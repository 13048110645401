.BackgroundSelection {
  margin: 0 20px;
  padding: 10px;
  border-radius: 10px;

  h3 {
    font-size: 20px;
    color: black;
  }
}

.BackgroundSelectionChoices {
  padding: 10px;
  flex-wrap: wrap;
  justify-content: center;
  filter: drop-shadow(0 0 5px rgba(black, 0.25));
}
