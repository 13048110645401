.ItemFilterType_Position {
  // background: rgba(white, 0.75);
  background: white;
  backdrop-filter: blur(5px);
  overflow-x: scroll;
}

.ItemFilterType {
  --item-size: 40px;

  display: flex;
  height: var(--VERTICAL_MARGIN);
  gap: 20px;
  align-items: center;

  padding-left: calc(50% - var(--item-size) / 2);
  padding-right: calc(50% - var(--item-size) / 2);

  outline: 1px solid red;
}

.ItemType {
  width: var(--item-size);
  height: var(--item-size);
  display: flex;
  align-items: center;
  justify-content: center;

  //filter: drop-shadow(0 0 5px white);
  border-radius: 5px;
  border: 1px solid white;
  cursor: pointer;
  transition: transform calc(var(--ANIMATIONS_DURATION) / 2);
}

.ItemTypeWrapper {
  border-radius: 5px;
}
