.ButtonBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  pointer-events: none;

  > * {
    pointer-events: auto;
  }
}
