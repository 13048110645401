.AddItemMenuButton_Position {
  backdrop-filter: blur(5px);
}

.AddItemMenuButton {
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;

  border-radius: 100px !important;

  .Button {
    border-radius: 100px !important;
  }
}
