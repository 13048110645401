.FilterStats {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex: 1 1;

  font-size: 15px;
  margin: 0;

  padding: 3px;
  background: rgba(white, 0.75);
  border-radius: 100px;
}

.FilterStatsInner {
  display: flex;
  gap: 10px;
  margin: 0 7px; // #1
}

.FilterStatsLoader,
.FilterStatsLoader,
.FilterStatsCounter,
.FilterStatsSummary {
  display: flex;
  gap: 5px;
  align-items: center;
}

.FilterStatsLoader {
  .Loader {
    margin-left: -7px; // synch with #1
    width: 21px !important;
    height: 21px !important;
    background: #999;
    border-top: 11px solid black;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    opacity: 1;
    @keyframes spin {
      0% { transform: rotate(0deg) scale(1); }
      50% { transform: rotate(180deg) scale(1.1); }
      100% { transform: rotate(360deg) scale(1); }
    }
  }

  .Loader {
    width: 21px !important;
    height: 21px !important;

    background: #999;
    border-top: 11px solid black;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    opacity: 1;
    @keyframes spin {
      0% {
        transform: rotate(0deg) scale(1);
      }
      50% {
        transform: rotate(180deg) scale(1.1);
      }
      100% {
        transform: rotate(360deg) scale(1);
      }
    }
  }
}

.FilterStatsShowAll {
  color: rgba(black, 0.25);
  display: flex;
  align-items: center;
  gap: 10px;
}
