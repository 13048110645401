.LocationItem {
  display: flex;
  flex-direction: column;
  gap: 5px;

  border-radius: 10px;

  padding: 5px 0;

  .LocationDescription {
    color: rgba(black, 0.5);
  }
}

.LocationItemProcessing {
  display: flex;
  color: rgba(black, 0.5);
  background-color: rgba(white, 0.75);
  border-radius: 100px;
  padding: 20px;
  align-items: center;

  .ButtonIcon {
    font-size: 30px;
  }

  span {
    flex: 1;
    max-width: calc(100vw - 140px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .Rotate {
    animation: spin 1s linear infinite;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

.LocationItem,
.LocationItemProcessing {
  a {
    color: black !important;
    text-decoration: none;
  }

  &.hasFailed {
    a {
      color: red !important;
    }
  }
}

.LocationMap {
  position: relative;
  display: flex;

  --LOCATION_MAP_SIZE: min(
    calc(100vw - 130px),
    calc(var(--MAX_ITEM_CONTENT_WIDTH) - 10px)
  );
  width: var(--LOCATION_MAP_SIZE);
  height: var(--LOCATION_MAP_SIZE);
  min-width: var(--LOCATION_MAP_SIZE);
  min-height: var(--LOCATION_MAP_SIZE);

  justify-content: center;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;

  img {
    height: 100%;
    width: auto;
  }

  &::before {
    position: absolute;
    height: 30px;
    width: 5px;
    background-color: rgba(red, 0.5);
    border: 1px solid rgba(white, 0.75);
    content: "";
  }

  &::after {
    position: absolute;
    width: 30px;
    height: 5px;
    background-color: rgba(red, 0.5);
    border: 1px solid rgba(white, 0.75);
    content: "";
  }
}

.LocationAttribution {
  font-size: 15px;
}

.LocationItemDateCaption {
  padding: 5px;
  font-size: 15px;
  color: rgba(black, 0.75);
}
