.ToggleControl {
  display: inline-flex;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  color: black;

  cursor: pointer;
  user-select: none;
  align-items: stretch;
  background: #ddd;
}

.ToggleControlTitle {
  font-weight: bold;
  font-size: 20px;
  flex: 1;
  text-align: center;
  flex-direction: column;
  padding: 10px;
  box-shadow: 0 0 10px rgba(black, 0.125);
}

.ToggleControlCaption {
  flex: 1;
  font-weight: bold;
  font-size: 17px;
}

.ToggleControlCaption.right {
  text-align: right;
}

.ToggleControlKnob {
  padding: 5px;
  padding-right: 10px;
  border-radius: 5px;

  min-width: 30px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.isTrue .ToggleControl {
  border-color: white;
  background-color: black;
}

.ToggleControlKnob.isSet {
  background: #bbb;
}

.ButtonBar .ToggleControl {
  background: rgba(black, 0.125);
}

.ToggleControlKnobs {
  padding: 10px;
}
