.ItemContent {
  flex: 1;
  display: flex;
  padding: 5px 0;
  padding-left: 35px;
  max-width: calc(100vw - 90px); // TODO hard coded 90px are not nice
  //overflow: hidden;
  // outline: 1px solid red;

  pointer-events: none;

  .isInteractivePluginComponent {
    pointer-events: auto;
    // outline: 1px solid red;
  }

  > * {
    max-width: var(--MAX_ITEM_CONTENT_WIDTH);
    // max-height: var(--MAX_ITEM_CONTENT_HEIGHT);
  }
}

.isOpened .ItemContent {
  pointer-events: auto;
}
