.Base64ImageItem {
  display: flex;

  border-radius: 5px;

  flex-direction: column;
  gap: 5px;

  margin: 5px 0;

  img {
    width: 100%;
    height: min-content;
    border-radius: 10px;
  }
}
