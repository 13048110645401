.HeartIndicator {
  padding: 3px;
  border-radius: 100px;
  background: #fff;

  // https://medium.com/@jeandesravines/use-border-radius-and-outline-simultaneously-on-safari-14ce92889e1f
  &::before {
    content: "";
    position: absolute;
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    border: 3px solid rgba(black, 0.125);
    border-radius: 100px;
  }
}
