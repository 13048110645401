.PromptBottomSheet {
  flex: 1;
  display: flex;
  flex-direction: column;

  input {
    font-size: 20px;
    padding: 10px;
    margin: 10px;

    outline: 0 !important;
    border: 0 !important;
  }
}

.PromptCaption {
  text-align: center;
  font-weight: bold;
  padding: 10px;
  font-size: 30px;
}
