.ItemInFocus_Position {
  transition: none;
  overflow-y: scroll;
}

.ItemInFocus {
  padding: 10px 0;
  padding-bottom: var(--VERTICAL_MARGIN);
  flex: 1;
  display: flex;
  flex-direction: column;
}

// .ItemInFocus > * {
//   width: 100%;
// }

.ItemInFocusPadder {
  display: flex;
  padding: 10px;
}
