.YouTubeItem {
  flex: 10;
  display: flex;

  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;

  width: calc(100vw - 90px); // TODO hard coded 90px are not nice
  max-width: 600px;

  margin: 5px 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
}
