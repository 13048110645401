.DateSeparator {
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.DateSeparatorContent {
  display: flex;
  gap: 10px;
  align-items: center;

  padding: 5px 20px;
  color: rgba(white, 0.75);
  z-index: 1;

  background: rgba(black, 0.125);
  border-radius: 50px;
}

.DateSeparatorDash {
  color: rgba(black, 0.125);
  margin: 0 5px;
}

.DateSeparatorLine {
  position: absolute;
  inset: 0;
  flex: 1 1;
  pointer-events: none;
  align-items: center;
  /* justify-content: center; */
  display: flex;

  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    border-bottom: 1px solid rgba(white, 0.5);
    top: 0;
  }
}
