.ItemProcessing {
  display: flex;
  flex: 1;
  color: rgba(black, 0.5);
  align-items: center;

  //outline: 1px solid green;

  .ButtonIcon {
    font-size: 30px;
  }

  span {
    flex: 1;
    max-width: calc(100vw - 140px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .Rotate {
    animation: spin 1s linear infinite;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
