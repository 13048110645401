.Item_Position {
  scroll-margin-top: 10px;
  scroll-margin-bottom: calc(2 * var(--VERTICAL_MARGIN) + 110px);

  /* https://developer.mozilla.org/en-US/docs/Web/CSS/content-visibility */
  // content-visibility: auto;
}

.Item {
  flex: 1;
  position: relative;

  margin-left: 25px;
  min-height: 60px; // if not set, there is a sudden "jump" after the loading spinner is done with spinning
  display: flex;
  background: #ddd;
  border-radius: 10px;
  cursor: pointer;
  user-select: none;
  align-items: center;
  font-size: 17px;

  // outline: 1px dotted orange;

  // transition: all var(--ANIMATIONS_DURATION);
  &.isOpened {
    background-color: white;
  }

  &.hasBeenUpdated {
    background-color: yellow;
    animation: hasBeenUpdatedAnimation var(--ANIMATIONS_DURATION);
    transform-origin: left center;

    @keyframes hasBeenUpdatedAnimation {
      0%,
      100% {
        transform: translateX(0);
      }
      50% {
        transform: scaleX(0.95);
      }
    }
  }
}

.isItemsListSwipedRight {
  .Item {
    transform: translateX(100px);
  }
}
