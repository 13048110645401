.TweetItem {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 200px;
  max-width: fit-content;
  width: 1000px;
  overflow: hidden;
  border-top-right-radius: 10px;

  -webkit-mask-image: linear-gradient(
    to bottom,
    black 0%,
    black 50%,
    transparent 100%
  );

  // that's referencing twitter's embed code
  .twitter-tweet {
    margin: 0 !important;
  }
}

.LoadingTweet {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  gap: 10px;
  background: white;
  border-radius: 10px;

  span {
    white-space: nowrap;
    color: rgba(black, 0.5);
  }

  &.hasLoadedWithError {
    span {
      color: red;
    }
  }
}
