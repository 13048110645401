.BasicItemIcon {
  display: flex;
  justify-content: center;
  align-items: center;

  //  outline: 0;

  color: black;
  height: 100%;
  min-height: 40px;
  min-width: 40px;
  border-radius: 100px;
  position: relative;
}
