.TodoItemCheckbox {
  &.isDone {
    animation: isDoneAnimation calc(var(--ANIMATIONS_DURATION) * 1);
  }
}

@keyframes isDoneAnimation {
  0%,
  100% {
    transform: rotate(0deg) scale(1);
  }

  25% {
    transform: rotate(-15deg) scale(1.75);
  }
}
