.ImageAlbum {
  // faster than normal
  transition: opacity calc(var(--ANIMATIONS_DURATION) / 2) ease-in-out;

  background: black;

  img {
    object-fit: contain;
  }
}
