.Position {
  display: flex;
  will-change: transform, opacity;
  // transition: transform var(--ANIMATIONS_DURATION) ease-in-out,
  //   opacity var(--ANIMATIONS_DURATION) ease-in-out;

  transition: opacity var(--ANIMATIONS_DURATION) ease-out,
    transform var(--ANIMATIONS_DURATION) ease-out;

  &.disallowTransition {
    will-change: unset;
    transition: unset;
  }
}
