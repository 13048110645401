.AddItemMenu {
  flex: 1;
  display: flex;
  background: linear-gradient(to bottom, #ddd, #fff);
  padding: 10px;
  flex-direction: column;
  gap: 10px;

  .grid {
    flex: 1;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3, 1fr);
  }
}
