.PushNotifications {
  padding: 10px;
}

.SinglePushNotification_Position {
  overflow: hidden;
}

.SinglePushNotification {
  flex: 1;
  background-color: rgba(yellow, 1);
  backdrop-filter: blur(2px);
  font-size: 15px;
  color: rgba(black, 0.75);

  display: flex;
  flex-direction: row;

  align-items: center;
}

.PushNotificationIcon {
  margin: 0 20px;
}

.PushNotificationCaption {
  margin: 10px;
  margin-left: 0;
}

.PushNotificationCaption {
  flex: 1;
}
