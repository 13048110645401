.MainMenu {
  padding-top: 60px;
  padding-bottom: 40px;
  color: #666;
  gap: 20px;
  overflow-y: scroll;
  background: #ccc;

  > span {
    text-align: center;
  }

  a {
    color: black;
    text-decoration: none;
    font-weight: bold;
    border-bottom: 3px solid rgba(black, 0.125);
  }

  .LogoWord {
    font-size: 100px;
    --OUTLINE-WIDTH: 10px;
  }
}

.FetchNewVersion {
  padding: 10px;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: 10px;
  background: #ddd;
}

.CopyDeviceId {
  margin: 0 20px;
  padding: 10px;
  border-radius: 10px;
  background: #ddd;
  text-align: center;
}
